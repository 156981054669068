<script setup lang="ts">
import { BreadcrumbsProps } from "./Breadcrumbs.props"
import { formatNameWithoutBackSlash } from "~/utils/formatters"

const {
  isDropdownActive = false,
  simpleVersion = false,
  hideFirstItemInMobile = false,
  links = []
} = defineProps<BreadcrumbsProps>()

const maxBreadcrumbsHeight = ref(0)

let resizeObserver: ResizeObserver | null = null

const setBreadcrumbsHeight = () => {
  const container = document.querySelector<HTMLElement>("#wrapper-container")
  const originalElement = document.querySelector<HTMLElement>("#desktop-ref")

  if (originalElement && container) {
    const clonedElement = originalElement.cloneNode(true) as HTMLElement

    clonedElement.id = "cloned-element"
    clonedElement.style.cssText = `
      position: absolute;
      visibility: hidden;
      user-select: none;
      pointer-events: none;
    `

    container.appendChild(clonedElement)

    resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        if (entry.target === clonedElement) {
          maxBreadcrumbsHeight.value = entry.contentRect.height
        }
      }
    })

    resizeObserver.observe(clonedElement)
  }
}

const cleanupBreadcrumbsHeight = () => {
  if (resizeObserver) {
    resizeObserver.disconnect()
    resizeObserver = null
  }
}

onMounted(() => setBreadcrumbsHeight())
onUnmounted(() => cleanupBreadcrumbsHeight())

const baseUrl = useRuntimeConfig().public.BASE_URL_APP

const breadcrumbListElement = computed(() => {
  const crumbsLength = links?.length ?? 1
  const crumbs = links.map((crumb, index) => ({
    "@type": "ListItem",
    position: index + 1,
    name: crumb?.label,
    item: `${baseUrl}${crumb?.to}`
  }))

  return crumbs
})

useHead({
  script: [
    {
      type: "application/ld+json",
      children: `{"@context": "https://schema.org", "@type": "BreadcrumbList", "itemListElement": ${JSON.stringify(
        cleanJsonLdObject(breadcrumbListElement.value)
      )}}`
    }
  ]
})

const prevLink = computed(() => {
  const linksLength = links.length
  if (linksLength === 1 && hideFirstItemInMobile) return false
  if (linksLength) return links[linksLength - 1]
})

const isActive = ref(isDropdownActive)

const toggleIsDropdownActive = () => {
  isActive.value = !isActive.value
}
</script>

<template>
  <div>
    <div v-if="simpleVersion">
      <span
        v-for="(link, index) in links"
        :key="`list${index}`"
        class="
          beaver-regular
          md:pig-regular
          inline-flex
          items-center
          text-black-main
        "
      >
        {{ formatNameWithoutBackSlash(link.label) }}
        <UtilsIcon
          name="ChevronRight.svg"
          class="h-[18px] w-[18px]"
          fetch-priority="high"
          :preload="true"
        />
      </span>
      <span
        v-if="currentPage"
        class="beaver-regular md:pig-regular text-black-main"
      >
        {{ formatNameWithoutBackSlash(currentPage) }}
      </span>
    </div>
    <div v-else id="wrapper-container">
      <!-- Mobile View-->
      <div
        class="mobile-breadcrumb-wrapper gap-1 bg-grey-main px-4 py-2 md:hidden"
        v-if="prevLink"
      >
        <UtilsButton
          class="custom-btn-no-padding custom-btn-start"
          :to="prevLink.to"
          :text="formatNameWithoutBackSlash(prevLink.label)"
          theme="ghost-black"
        >
          <template #icon>
            <UtilsIcon
              name="ArrowLeft.svg"
              class="h-[18px] w-[18px]"
              fetch-priority="high"
              :preload="true"
            />
          </template>
        </UtilsButton>
      </div>

      <!-- Desktop View -->
      <div
        id="desktop-ref"
        class="
          desktop-breadcrumb-wrapper
          padded
          relative
          hidden
          bg-grey-main
          py-[6px]
          md:flex
        "
        aria-label="Breadcrumb"
      >
        <!-- Classic Breadcrumb -->
        <div
          v-if="maxBreadcrumbsHeight <= 20"
          class="classic-breadcrumb flex flex-wrap items-center"
        >
          <div
            v-for="({ label, to }, index) in links"
            :key="`list${index}`"
            class="inline-flex items-center"
          >
            <UtilsButton
              class="
                custom-btn-mouse-medium
                custom-btn-underlined
                custom-btn-no-padding
                cursor-pointer
              "
              :to="to"
              :text="formatNameWithoutBackSlash(label)"
              theme="ghost-green"
            />

            <UtilsIcon
              name="ChevronRight.svg"
              class="mx-1 h-[18px] w-[18px]"
              fetch-priority="high"
              :preload="true"
            />
          </div>
          <p v-if="currentPage" class="mouse-bold text-black-main">
            {{ formatNameWithoutBackSlash(currentPage) }}
          </p>
        </div>

        <!-- Extended Breadcrumb  -->
        <div v-else class="extended-breadcrumb flex items-center">
          <div
            :class="{ 'bg-grey-100': isActive }"
            class="cursor-pointer rounded hover:bg-grey-main"
          >
            <UtilsIcon
              name="ThreePoints.svg"
              class="h-4 w-4"
              @click="toggleIsDropdownActive()"
              fetch-priority="high"
              :preload="true"
            />
          </div>

          <UtilsIcon
            name="ChevronRight.svg"
            class="mx-1 h-[18px] w-[18px]"
            fetch-priority="high"
            :preload="true"
          />

          <p v-if="currentPage" class="mouse-bold text-black-main">
            {{ formatNameWithoutBackSlash(currentPage) }}
          </p>
        </div>

        <!-- Dropdown Menu -->
        <div
          class="
            dropdown-menu
            absolute
            top-7
            z-10
            flex flex-col
            gap-1
            rounded-lg
            bg-grey-main
            p-2
            shadow-01
          "
          v-if="isActive"
        >
          <li
            v-for="({ id, label, to }, index) in links"
            :key="id"
            class="flex items-center"
          >
            <UtilsIcon
              name="ChevronRight.svg"
              v-if="index !== links.length - links.length"
              class="mx-1 h-[18px] w-[18px]"
              fetch-priority="high"
              :preload="true"
            />

            <UtilsButton
              class="
                custom-btn-underlined
                custom-btn-no-padding
                custom-btn-mouse-medium
                cursor-pointer
              "
              :to="to"
              :text="formatNameWithoutBackSlash(label)"
              theme="ghost-green"
            />
          </li>
        </div>
      </div>
    </div>
  </div>
</template>
